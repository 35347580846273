var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"3","sm":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('div',{attrs:{"role":"tablist"}},[_c('b-card',{class:[this.$vuetify.theme.dark ? 'dark-bg' : '', 'mb-1'],attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('v-list-item',{directives:[{name:"b-toggle",rawName:"v-b-toggle.settings",modifiers:{"settings":true}}],staticClass:"ma-0",on:{"click":function($event){_vm.globalSettings = !_vm.globalSettings}}},[_c('v-list-item-title',{class:this.globalSettings
                        ? 'primary--text text-wrap'
                        : 'text-wrap'},[_vm._v(" "+_vm._s(_vm.$t('settings.lang_settings'))+" ")]),_c('v-list-item-action',[(!this.globalSettings)?_c('v-icon',[_vm._v("keyboard_arrow_down")]):_vm._e(),(this.globalSettings)?_c('v-icon',[_vm._v("keyboard_arrow_up")]):_vm._e()],1)],1)],1),_c('b-collapse',{attrs:{"visible":this.globalSettings,"accordion":"settings","id":"settings","role":"tabpanel"}},[_c('b-card-body',{staticClass:"pa-0"},[_c('b-list-group',{attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'generalInfo'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('generalInfo')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_globalInformations'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:this.currentTab === 'payment' ? 'primary--text' : '',on:{"click":function($event){return _vm.handleClick('payment')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_zahlungsoptionen'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'emailConfig'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('emailConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('settings.lang_emailSettings'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'dynamicContent'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('dynamicContent')}}},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_dynamicContent'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'imageTemplate'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('imageTemplate')}}},[_vm._v(" "+_vm._s(_vm.$t('settings.lang_imageTemplates'))+" ")]),_c('b-list-group-item',{staticClass:"items",class:[
                        this.$vuetify.theme.dark ? 'dark-bg' : '',
                        this.currentTab === 'cardTemplates'
                          ? 'primary--text'
                          : '',
                      ],on:{"click":function($event){return _vm.handleClick('cardTemplates')}}},[_vm._v(" "+_vm._s(_vm.$t('settings.lang_cardTemplates'))+" ")])],1)],1)],1)],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"9","sm":"12"}},[(_vm.currentTab === 'generalInfo')?_c('div',[_c('OnlineGiftCardSaleGeneralInfoComponent')],1):_vm._e(),(_vm.currentTab === 'payment')?_c('div',[_c('OnlineGiftCardSalePaymentOptionComponent')],1):_vm._e(),(_vm.currentTab === 'emailConfig')?_c('div',[_c('OnlineGiftCardSaleEmailConfigComponent')],1):_vm._e(),(_vm.currentTab === 'dynamicContent')?_c('div',[_c('OnlineGiftCardSaleDynamicContentComponent')],1):_vm._e(),(_vm.currentTab === 'imageTemplate')?_c('div',[_c('ImagesTemplate')],1):_vm._e(),(_vm.currentTab === 'cardTemplates')?_c('div',[_c('VoucherbeeCardTemplates')],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }