<template>
  <v-card :loading="loading" elevation="0">
    <v-card-title class="card-header card-header-tab bg-white border-0 transparent">
      <div :class="[this.$vuetify.theme.dark? 'white--text' : '']" class="card-title text-capitalize pt-3">
        {{ $t('generic.lang_globalSettings') }}
      </div>
    </v-card-title>
    <v-divider class="ma-0" />
    <v-card-text>
      <v-form ref="form" v-model="valide" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="6">
              <div class="d-flex">
                <v-switch v-model="form.hideAvailableKontingent" :label="$t('eventbee.lang_hideAvailableTickets')" dense
                  outlined prepend-inner-icon="mdi-ticket-outline" class="mr-1"></v-switch>

                <v-tooltip top nudge-bottom="20" nudge-left="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("eventbee.lang_hideAvailableTicketsTooltip") }}
                  </span>
                </v-tooltip>
              </div>

              <div class="d-flex">
                <v-switch v-model="form.requireDate" :label="$t('eventbee.lang_requireDateInput')" dense outlined
                  prepend-inner-icon="mdi-ticket-outline" class="mr-1"></v-switch>

                <v-tooltip top nudge-bottom="20" nudge-left="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("eventbee.lang_requireDateTooltip") }}
                  </span>
                </v-tooltip>
              </div>

              <div class="d-flex">
                <v-switch v-model="form.displayGroupsFirstPage" :label="$t('eventbee.lang_displayGroupsFirstPage')"
                  dense outlined prepend-inner-icon="mdi-ticket-outline" class="mr-1"></v-switch>

                <v-tooltip top nudge-bottom="20" nudge-left="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("eventbee.lang_displayGroupsFirstPageTooltip") }}
                  </span>
                </v-tooltip>
              </div>

              <div class="d-flex">
                <v-switch v-model="form.activatePrecreatedTicketUuids"
                  :label="$t('eventbee.lang_activatePrecreatedTicketUuids')" dense outlined
                  prepend-inner-icon="mdi-ticket-outline" class="mr-1"></v-switch>

                <v-tooltip top nudge-bottom="20" nudge-left="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("eventbee.lang_activatePrecreatedTicketUuidsTooltip") }}
                  </span>
                </v-tooltip>
              </div>

              <div class="d-flex">
                <v-switch v-model="form.eb_mergeEventPlans" :label="$t('eventbee.lang_mergeEventPlans')" dense outlined
                  prepend-inner-icon="mdi-ticket-outline" class="mr-1"></v-switch>

                <v-tooltip top nudge-bottom="20" nudge-left="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("eventbee.lang_mergeEventPlansTooltip") }}
                  </span>
                </v-tooltip>
              </div>

              <div class="d-flex">
                <v-switch v-model="form.eb_hideStartDateEndDate" :label="$t('eventbee.lang_hideStartDateEndDate')" dense
                  outlined prepend-inner-icon="mdi-ticket-outline" class="mr-1"></v-switch>

                <v-tooltip top nudge-bottom="20" nudge-left="100">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                      mdi-information
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("eventbee.lang_hideStartDateEndDateTooltip") }}
                  </span>
                </v-tooltip>
              </div>
              <div class="d-flex">
                <v-radio-group v-model="form.eb_seatSelectionMode" mandatory column :label="$t('eventbee.lang_seatSelectionMode')" hide-details>
                  <v-radio :value="0">
                    <template v-slot:label>
                      {{$t('generic.lang_both')}}
                    </template>
                  </v-radio>
                  <v-radio :value="1">
                    <template v-slot:label>
                      {{$t('eventbee.lang_bestSeat')}}
                      <v-tooltip top nudge-bottom="20" nudge-left="100">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span>
                          {{ $t("eventbee.lang_bestSeatModeToolTip") }}
                        </span>
                      </v-tooltip>
                    </template>
                  </v-radio>
                  <v-radio :value="2">
                    <template v-slot:label>
                      {{$t('eventbee.lang_chooseSeat')}}
                      <v-tooltip top nudge-bottom="20" nudge-left="100">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon color="primary" dark size="20" v-bind="attrs" v-on="on">
                            mdi-information
                          </v-icon>
                        </template>
                        <span>
                          {{ $t("eventbee.lang_chooseSeatModeToolTip") }}
                        </span>
                      </v-tooltip>
                    </template>
                  </v-radio>
                </v-radio-group>
              </div>
            </v-col>

            <v-col cols="12">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                @keypress="acceptOnlyPositiveNumbers" autocomplete="off" :label="$t('eventbee.lang_gracePeriodCheckIn')"
                dense outlined min="0" type="number" v-model="form.eb_gracePeriodCheckIn">
                <template v-slot:append-outer>
                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_gracePeriodCheckInTooltip") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                @keypress="acceptOnlyPositiveNumbers" autocomplete="off"
                :label="$t('eventbee.lang_gracePeriodCheckOut')" dense outlined min="0" type="number"
                v-model="form.eb_gracePeriodCheckOut">
                <template v-slot:append-outer>
                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_gracePeriodCheckOutTooltip") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" @focus="showTouchKeyboard"
                @keypress="acceptOnlyPositiveNumbers" autocomplete="off"
                :label="$t('eventbee.lang_earlisetCheckoutAfterCheckin')" dense outlined min="0" type="number"
                v-model="form.eb_earlisetCheckoutAfterCheckin">
                <template v-slot:append-outer>
                  <v-tooltip top nudge-left="100">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on"> mdi-information </v-icon>
                    </template>
                    <span>
                      {{ $t("eventbee.lang_earlisetCheckoutAfterCheckinTooltip") }}
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="border-top">
      <v-spacer />
      <v-btn :disabled="loading||!valide" @click="update()" color="success">
        {{ $t('generic.lang_apply') }}
      </v-btn>
    </v-card-actions>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard" :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
        :layout="touchKeyboard.layout" :options="touchKeyboard.options" class="internalWidth" />
    </div>
  </v-card>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "EventbeeGlobalSettingsComponent",
  mixins: [mixin],
  data() {
    return {
      loading: false,
      valide: false,
      form: {
        hideAvailableKontingent : false,
        requireDate : false,
        displayGroupsFirstPage : false,
        activatePrecreatedTicketUuids: false,
        eb_mergeEventPlans: false,
        eb_gracePeriodCheckIn: 0,
        eb_gracePeriodCheckOut: 0,
        eb_earlisetCheckoutAfterCheckin: 0,
        eb_seatSelectionMode: 0,
      }
    }
  },
  mounted() {
    this.getConfig();
  },
  methods: {
    acceptOnlyPositiveNumbers(event) {
      if (isNaN(event.key)) event.preventDefault();
    },
    getConfig() {
      this.loading = true;

      this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.GLOBALSETTINGS.GET)
          .then(res => {
            this.form = Object.assign({}, res.data);
            this.form.requireDate = res.data.eb_requiredDateInput;
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    update() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.axios.post(ENDPOINTS.EVENTBEE.SETTINGS.GLOBALSETTINGS.UPDATE, this.form).then(res => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_updatedSuccessfully'),
          color: "success",
        });
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped>

</style>

