import { render, staticRenderFns } from "./OnlineGiftCardSaleGeneralInfoComponent.vue?vue&type=template&id=42615062&scoped=true"
import script from "./OnlineGiftCardSaleGeneralInfoComponent.vue?vue&type=script&lang=js"
export * from "./OnlineGiftCardSaleGeneralInfoComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42615062",
  null
  
)

export default component.exports