<template>
    <v-card :loading="loading">
        <v-card-title> {{ $t('settings.lang_cardTemplates') }}<v-spacer />
            <v-btn color="primary" depressed link to="/settings/moduleSettings/giftcardsale/cardtemplate/create">{{ $t("generic.lang_add") }}</v-btn>
        </v-card-title>
        <v-divider class="ma-0" />
        <v-card-text class="pa-0">
            <Datatable ref="cardTemplatesDatatable"
                           :api-endpoint="ENDPOINTS.DATATABLES.VOUCHERBEE.CARDTEMPLATES"
                           :datatable-headers="datatableHeaders"
                           @editEntry="entryEdit"
                           @deleteEntry="entryDelete"
                           @deleteEntries="entriesDelete"
                           no-excel-export
                           show-delete-buttons
                           show-edit-buttons
                />
        </v-card-text>
    </v-card>
</template>
<script >
import { ENDPOINTS } from '@/config';
import { Events } from "@/plugins/events";
import Datatable from "@/components/datatable/Datatable";

export default {
    name: 'VoucherbeeCardTemplates',
    components: {
            Datatable
        },
        data() {
            return {
                ENDPOINTS,
                id:null,
                loading: false,
            }
        },
        computed: {
            // --- Datatable ---
            datatableHeaders() {
                return [
                    {
                    text: 'ID',
                    align: 'left',
                    value: 'id',
                    width: 80,
                    },
                    {text: this.$t('generic.lang_title'), value: 'name'},
                ];
            }
        },
        methods: {
            deleteData (idsToDelete = []) {
                this.$swal({
                    title: this.$t('settings.lang_deleteTemplate'),
                    text: this.$t('generic.lang_deleteConfirmationMSG'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.VOUCHERBEE.SETTINGS.CARDTEMPLATE.DELETE, {
                            ids: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_deletedSuccessfully'),
                                    color: "success"
                                });
                                this.id=null;
                                this.$refs.cardTemplatesDatatable.getDataFromApi();
                                this.$refs.cardTemplatesDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        }).finally(() => {
                            this.loading = false;
                        })
                    },
                    allowOutsideClick: () => !this.$swal.isLoading
                });
            },
            entryEdit(entry) {
                this.$router.push({name: 'settings.moduleSettings.giftcardsale.cardtemplate.edit', params: {id: parseInt(entry.id)}});
            },
            entryDelete(entry) {
                this.id = entry.id;
                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            }
        }
    }
</script>